@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

p {
  padding-bottom: 1.5rem;
}
