@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

@layer base {
  body {
    @apply font-sans;
  }
}

.thing-enter {
  transform: translateY(100px);
  opacity: 0;
}

.thing-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 500ms, transform 500ms;
}

@keyframes random-shake {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(var(--x), var(--y));
  }
}

.hover-wave span {
  display: inline-block;
  transition: transform 0.1s ease-in-out;
}

.animate-shake span {
  animation: random-shake 0.35s ease;
}

.white-wash {
  display: inline-block;
  color: transparent;

  background: linear-gradient(
    90deg,
    rgba(41, 95, 209, 0.8) 0%,
    rgba(41, 95, 209, 0.8) 30%,
    #faf8f6 50%,
    rgba(41, 95, 209, 0.8) 70%,
    rgba(41, 95, 209, 0.8) 100%
  );

  background-size: 300%;
  background-position: 100%; /* Start at the right */
  -webkit-background-clip: text;
  background-clip: text;
}

.wave-animate {
  animation: wave 0.85s ease-out forwards;
}

@keyframes wave {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}
